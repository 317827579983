import React, { lazy, Suspense } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import LayoutWrapper from '../../components/layout/LayoutWrapper';
import Loader from '../../components/loader/fallbackLoader';
import Error from '../Error';
import PrivateRoute from './ProtectedRoute';

const LazyLogin = lazy(() => import('../login'));
const LazyForgotPassword = lazy(() => import('../forgotPassword'));
const LazyUserAccessPanel = lazy(() => import('@/pages/userAccessPanel'));
const LazyOtpVerify = lazy(() => import('@/pages/otpVerify'));

const LazyMdmsUtility = lazy(() => import('../utility/module/mdms'));
const LazyHes = lazy(() => import('@/pages/utility/module/hesv1'));
const LazySat = lazy(() => import('@/pages/utility/module/sat'));
// const LazySlaReports = lazy(() => import('@/pages/utility/module/sla-Reports'));
const LazyGis = lazy(() => import('@/pages/utility/module/gis'));

const Router = () => {
  return (
    <HashRouter>
      <Suspense
        fallback={<Loader height={'800px'} imgSrc={'polaris-logo.svg'} />}
      >
        <Routes>
          <Route path="/" element={<LazyLogin />} />

          <Route path="forgot-password" element={<LazyForgotPassword />} />
          <Route path="otp-verify" element={<LazyOtpVerify />} />
          <Route element={<PrivateRoute />}>
            <Route
              path="utility/lpdd/hes"
              element={
                <LayoutWrapper>
                  <LazyHes />
                </LayoutWrapper>
              }
            />
            <Route
              path="utility/lpdd/mdms"
              element={
                <LayoutWrapper>
                  <LazyMdmsUtility />
                </LayoutWrapper>
              }
            />
            <Route
              path="utility/lpdd/gis"
              element={
                <LayoutWrapper>
                  <LazyGis />
                </LayoutWrapper>
              }
            />{' '}
            <Route
              path="utility/lpdd/sat"
              element={
                <LayoutWrapper>
                  <LazySat />
                </LayoutWrapper>
              }
            />
            {/* <Route
              path="utility/lpdd/sla-reports"
              element={
                <LayoutWrapper>
                  <LazySlaReports />
                </LayoutWrapper>
              }
            /> */}
          </Route>

          <Route
            path="admin"
            element={
              <LayoutWrapper>
                <LazyUserAccessPanel />
              </LayoutWrapper>
            }
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default Router;
